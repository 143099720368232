<template>
  <label class="checkbox">
    <InputCheckbox
      class="dot checkbox__dot"
      :name="name"
      @update:model-value="$emit('update:modelValue', $event)"
      :model-value="modelValue"
      :value="value"
      :checked="checked"
    />
    <span class="button checkbox__button">
      {{ label }}
    </span>
  </label>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Boolean],
    },
    value: {
      type: [String, Boolean],
      default: "",
    },
    label: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    checked: {
      type: Boolean,
      default: null,
    },
  },

  emits: ["update:modelValue"],
};
</script>

<style scoped>
.checkbox {
  display: flex;
}

.checkbox__button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--checkbox-button--width);
  height: var(--checkbox-button--height);

  color: var(--checkbox--font-color);
  font-family: var(--default-font-family);
  font-weight: var(--checkbox-button--font-weight);

  background-color: var(--checkbox--bg-color);
  border: var(--checkbox--border);
  border-radius: var(--checkbox-button--border-radius);

  cursor: pointer;
}

.checkbox__button:hover {
  border: var(--checkbox-hover--border);
}

.checkbox__dot--hide {
  display: none;
}

.checkbox__dot:checked + .checkbox__button {
  border: var(--checkbox-active--border);
  background-color: var(--checkbox-active--bg-color);
}
</style>
