import { prepareApplicationNannyInstance } from "./prepareApplicationNannyInstance";
import { prepareOfferNannyInstance } from "./prepareOfferNannyInstance";

export const prepareOfferNannyResponseInstance = (responseRaw) => {
  return {
    _id: responseRaw._id,
    get id() {
      return this._id;
    },
    from_application_nanny_id: responseRaw.from_application_nanny_id,
    from_application_nanny_id_populated:
      responseRaw.from_application_nanny_id_populated
        ? prepareApplicationNannyInstance(
            responseRaw.from_application_nanny_id_populated
          )
        : null,
    on_offer_nanny_id: responseRaw.on_offer_nanny_id,
    on_offer_nanny_id_populated: responseRaw.on_offer_nanny_id_populated
      ? prepareOfferNannyInstance(responseRaw.on_offer_nanny_id_populated)
      : null,
    message: responseRaw.message,
  };
};
