<template>
  <h1 class="h1" :class="h1Class">
    <slot>{{ text }}</slot>
  </h1>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    short: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const h1Class = computed(() => {
      return {
        "h1--short": props.short,
      };
    });
    return {
      h1Class,
    };
  },
};
</script>

<style scoped>
.h1 {
  display: block;
  font-family: var(--h1--font-family);
  font-size: var(--h1--font-size);
  font-weight: var(--h1--font-weight);
  line-height: normal;
  text-align: center;
}
.h1--short {
  font-size: var(--h1-short--font-size);
}
</style>
